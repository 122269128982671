import React from 'react'
import ReactDOM from 'react-dom/client'
import { BrowserRouter } from 'react-router-dom';
// import { configureStore } from '@reduxjs/toolkit';
import { Provider } from 'react-redux';

import App from './App.js'
import './index.css'
import store from './redux/store.js';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

ReactDOM.createRoot(document.getElementById('root')).render(
  <BrowserRouter>
    <Provider store={store} >
      <App />
      <ToastContainer />
    </Provider>
  </BrowserRouter>,
)