import React, { useEffect, useState } from "react";

import DashboardCard from "./dashboardCard";
import SideBar from "../Parts/SideBar2";
import { useNavigate } from "react-router-dom";
import { deletePdf, getAllPdfs, toggleActive, togglePdfActive } from "../../api/pdfApi";
import { deleteVideo, getAllVideos, toggleVideoActive } from "../../api/videoApi";
import { deleteArticle, getAllArticles, toggleArticleActive } from "../../api/articleApi";
import { selectLoggedInUser } from "../../redux/authSlice";
import { useSelector } from "react-redux";
import { toast } from 'react-toastify';

const DocRepoDashboard = () => {
  const [pdfs, setPdfs] = useState([]);
  const [articles, setArticles] = useState([]);
  const [videos, setVideos] = useState([]);
  const nav = useNavigate();

  const mainUser = useSelector(selectLoggedInUser);

  const [filter, setFilter] = useState("all"); // Possible values: "all", "published", "unpublished"

  const [searchQuery, setSearchQuery] = useState('');

  useEffect(() => {
    fetchPdfs();
    fetchArticles();
    fetchVideos();
  }, [])

  // articles
  const fetchArticles = async () => {
    try {
      const res = await getAllArticles();
      setArticles(res);
    } catch (err) {
      toast.error("Error getting articles.");
    }
  }
  const editArticle = async (id) => {
    nav(`/admin/article/${id}`);
  }
  const delArticle = async (id) => {
    try {
      await deleteArticle(id);
      fetchArticles();
    } catch (err) {
      console.error(err);
    }
  }

  // pdfs
  const fetchPdfs = async () => {
    try {
      const res = await getAllPdfs();
      setPdfs(res);
    } catch (err) {
      toast.error("Error fetching pdfs.");
    }
  }
  const editPdf = async (id) => {
    nav(`/admin/pdf/${id}`);
  }
  const delPdf = async (id) => {
    try {
      await deletePdf(id);
      fetchPdfs();
    } catch (err) {
      console.error(err);
    }
  }

  // videos
  const fetchVideos = async () => {
    try {
      const res = await getAllVideos();
      setVideos(res);
    } catch (error) {
      toast.error("Error fetching videos.");
    }
  }
  const editVideo = async (id) => {
    nav(`/admin/video/${id}`);
  }
  const delVideo = async (id) => {
    try {
      await deleteVideo(id);
      fetchVideos();
    } catch (error) {
      console.error(error);
    }
  }

  const handlePdfActive = async (id, stat) => {
    try {
      const updated = await togglePdfActive(id, { isActive: !stat })
      setPdfs(pdfs.map(p =>
        p._id === id ? { ...p, isActive: updated.isActive } : p
      ));
    } catch (error) {
      console.error(error);
    }
  }

  const handleVideoActive = async (id, stat) => {
    try {
      const updated = await toggleVideoActive(id, { isActive: !stat })
      setVideos(videos.map(p =>
        p._id === id ? { ...p, isActive: updated.isActive } : p
      ));
    } catch (error) {
      console.error(error);
    }
  }

  const handleArticleActive = async (id, stat) => {
    try {
      const updated = await toggleArticleActive(id, { isActive: !stat })
      setArticles(articles.map(p =>
        p._id === id ? { ...p, isActive: updated.isActive } : p
      ));
    } catch (error) {
      console.error(error);
    }
  }

  const filteredPdfs = pdfs?.filter(pdf => {
    const matchesFilter = filter === "all" || (filter === "published" && pdf.isActive) || (filter === "unpublished" && !pdf.isActive);
    const matchesSearch = pdf.pdfName.toLowerCase().includes(searchQuery.toLowerCase());
    return matchesFilter && matchesSearch;
  });

  const filteredVideos = videos?.filter(video => {
    const matchesFilter = filter === "all" || (filter === "published" && video.isActive) || (filter === "unpublished" && !video.isActive);
    const matchesSearch = video.videoName.toLowerCase().includes(searchQuery.toLowerCase());
    return matchesFilter && matchesSearch;
  });

  const filteredArticles = articles?.filter(article => {
    const matchesFilter = filter === "all" || (filter === "published" && article.isActive) || (filter === "unpublished" && !article.isActive);
    const matchesSearch = article.title.toLowerCase().includes(searchQuery.toLowerCase());
    return matchesFilter && matchesSearch;
  });



  return (
    <div className="flex overflow-hidden">
      <SideBar />
      <div className="ml-[15.5vw] p-4">
        <header>
          <h1 className="text-2xl font-bold mb-4">Hi, {mainUser.user.name || "Name"}</h1>
        </header>
        <div className="flex flex-col items-center -ml-1">
          <div className="flex w-[1200px] border-2 rounded-t-lg px-4 pt-2 justify-between items-center">
            <div className="flex flex-col">
              <h2 className="text-xl font-semibold mb-1">
                Document Repo Dashboard
              </h2>
              <p className="text-gray-600 mb-4">Keep track of your documents.</p>
            </div>

            <div className=" group">
              <button
                className="h-[39px] px-6 py-2 mb-4 bg-orange-500 text-white rounded">
                + Add New Document
              </button>
              <div className="absolute z-10 text-center w-40 bg-white divide-y divide-gray-100 rounded-md shadow-lg opacity-0 invisible group-hover:opacity-100 group-hover:visible transition duration-300">
                <div className="py-1 flex flex-col">
                  <a href="/admin/pdf" className=" mx-3 my-1 bg-orange-200 rounded p-1">Pdf</a>
                  <a href="/admin/video" className=" mx-3 my-1 bg-orange-200 rounded p-1">Video</a>
                  <a href="/admin/article" className=" mx-3 my-1 bg-orange-200 rounded p-1">Article</a>
                </div>
              </div>
            </div>
          </div>

          <div className="flex w-[1200px] space-x-[540px] border-2  px-2 py-2 -translate-y-[2px] -translate-x-[14.8px]  ml-[30px]">
            <div
              className="mt-2 h-[35px] inline-flex rounded-md shadow-sm translate-x-[6px] translate-y-[4px]"
              role="group"
            >
              <button
                type="button"
                className={`px-4 py-2 text-sm font-medium text-gray-900 border border-gray-200 rounded-s-lg ${filter === 'all' ? 'bg-blue-500 text-white' : 'bg-white hover:bg-gray-100 hover:text-blue-700'}`}
                onClick={() => setFilter("all")}
              >
                View All
              </button>
              <button
                type="button"
                className={`px-4 py-2 text-sm font-medium text-gray-900 border-t border-b border-gray-200 ${filter === 'published' ? 'bg-blue-500 text-white' : 'bg-white hover:bg-gray-100 hover:text-blue-700'}`}
                onClick={() => setFilter("published")}
              >
                Published
              </button>
              <button
                type="button"
                className={`px-4 py-2 text-sm font-medium text-gray-900 border border-gray-200 rounded-e-lg ${filter === 'unpublished' ? 'bg-blue-500 text-white' : 'bg-white hover:bg-gray-100 hover:text-blue-700'}`}
                onClick={() => setFilter("unpublished")}
              >
                Unpublished
              </button>

            </div>

            <div className="flex items-center space-x-2 mb-4">
              <input
                type="text"
                placeholder="Search"
                className="border-2 translate-y-[8px] w-[250px] h-[40px] px-4 py-3 rounded-lg"
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
              />
              <button className="translate-y-[8px] h-[40px] px-4 py-2 bg-gray-200 rounded">
                Filters
              </button>
            </div>
          </div>
        </div>

        <div className="w-[1200px] ml-[35.5px] grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4 border-2 rounded-b-lg px-4 py-4 -translate-x-[20px] -translate-y-[3px]">
          {filteredPdfs?.map((card, index) => (
            <DashboardCard
              key={card._id ?? index}
              name={card.pdfName}
              code={"PDF"}
              date={card.createdAt.substr(0, 10)}
              edit={() => editPdf(card._id)}
              deletee={() => delPdf(card._id)}
              isActive={card.isActive}
              toggleActive={() => handlePdfActive(card._id)}
            />
          ))}
          {filteredVideos?.map((video, i) => (
            <DashboardCard
              key={video._id ?? i}
              name={video.videoName}
              code={"Video"}
              date={video.createdAt.substr(0, 10)}
              edit={() => editVideo(video._id)}
              deletee={() => delVideo(video._id)}
              isActive={video.isActive}
              toggleActive={() => handleVideoActive(video._id)}
            />
          ))}
          {filteredArticles?.map((article, i) => (
            <DashboardCard
              key={article._id ?? i}
              name={article.title}
              code={"Article"}
              date={article.createdAt.substr(0, 10)}
              edit={() => editArticle(article._id)}
              deletee={() => delArticle(article._id)}
              isActive={article.isActive}
              toggleActive={() => handleArticleActive(article._id)}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

export default DocRepoDashboard;
