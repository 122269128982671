import React from "react";
import pencil from '../../assets/pencil.svg';
import bin from '../../assets/bin.svg';

const SubTopicResource = ({typeOfFile, headingName, ranking, handleRanking, handleRemove}) => {
  return (
    <div className=" w-[240px] grow basis-0 p-4 bg-white rounded-xl border border-gray-300 flex-col justify-start items-center gap-4 inline-flex">
      <div className="self-stretch h-12 flex-col justify-center items-center gap-3 flex">
        <div className="self-stretch h-12 justify-between items-center inline-flex">
          <div className="grow shrink basis-0 h-12 flex-col justify-between items-start inline-flex">
            <p className="self-stretch text-gray-950 text-lg font-bold font-['Inter'] leading-normal">
              {!headingName ? "Sub-Topic" : headingName}
            </p>
            {typeOfFile && (
              <p className="self-stretch text-gray-600 text-sm font-normal font-['Inter'] leading-tight">
              Type - {typeOfFile}
            </p>
            )}
          </div>
        </div>
      </div>
      <div className="self-stretch justify-between items-center inline-flex">
        <div className="grow shrink basis-0 h-6 justify-between items-center flex">
          <div className="flex-col justify-center items-center inline-flex">
            <label htmlFor="rank" className="self-stretch text-center text-gray-600 text-xs font-medium font-['Inter'] leading-normal">
              Ranking
            </label>
          </div>
          <input 
            type="number"
            id="rank"
            value={ranking}
            onChange={e => handleRanking(e.target.value)}
            className="w-[80px] h-6 bg-purple-100 text-center rounded-[15px] justify-center items-center gap-2.5 flex focus:outline-none"
          />
        </div>
      </div>
      <div className="self-stretch justify-start items-center gap-3 inline-flex">
        <button onClick={handleRemove} className="grow shrink basis-0 h-8 px-[31px] py-1 rounded-[30px] border border-red-500 justify-center items-center gap-[5px] flex">
          <img src={bin} alt="dustbin" width={15}/>
          <p className="text-center text-red-500 text-xs font-medium font-['Inter'] leading-normal">
            Remove
          </p>
        </button>
      </div>
    </div>
  );
};

export default SubTopicResource;
