import React, { useEffect, useRef, useState } from "react";
import arrow from "../../assets/chevron-down.svg";

const DropDown = ({ fieldName, array, handleInput, name, value, i, required }) => {
  const dropdownRef = useRef(null);

  const [showOptions, setShowOptions] = useState(false);
  const [inputValue, setInputValue] = useState(value || "");

  useEffect(() => {
    setInputValue(value || "")
  }, [value]);

  useEffect(() => {
    // Function to handle click outside
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setShowOptions(false);
      }
    };

    // Attach event listener
    document.addEventListener('mousedown', handleClickOutside);

    // Cleanup event listener
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const handleSelect = (selectedValue) => {
    setInputValue(selectedValue);
    setShowOptions(false);

    handleInput({ target: { name: name, value: selectedValue } }, i ? i : null);
  }

  return (
    <div ref={dropdownRef} className="flex flex-col w-full">
      <label htmlFor={fieldName} className="w-[200px] text-zinc-700 text-base font-semibold leading-tight">
        {fieldName}
      </label>
      <div className="self-stretch bg-white rounded-lg shadow border border-gray-900 justify-start items-center gap-2 inline-flex">
        <div className="w-full px-3.5 py-2.5 text-gray-700 focus:outline-none">
          {inputValue}{required && <span className="text-red-500">*</span>}
        </div>
        <button
          type="button"
          className="px-3.5 py-2.5 hover:bg-violet-50"
          onClick={() => setShowOptions(!showOptions)}>
          <img src={arrow} alt="arrow" className="w-5 h-5 relative" />
        </button>
      </div>
      {showOptions && (
        <div className="self-stretch z-10 mt-1 bg-white divide-y divide-gray-100 rounded-md shadow-lg max-h-40 overflow-x-auto">
          {array.map((field, index) => (
            <p key={index}
              className="px-3.5 py-2.5 hover:bg-violet-50 cursor-pointer"
              onClick={() => handleSelect(field)}>
              {field}
            </p>
          ))}
        </div>
      )}
    </div>
  );
};

export default DropDown;
