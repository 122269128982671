import React from "react";
import { IKContext, IKUpload } from "imagekitio-react";

const url = process.env.REACT_APP_URL_ENDPOINT;
const publicKey = process.env.REACT_APP_PUBLIC_KEY;
const authenticator = async () => {
  try {
    const response = await fetch(
      `https://www.gradbudy.work.gd/file/auth`
    );

    if (!response.ok) {
      const errorText = await response.text();
      throw new Error(
        `Request failed with status ${response.status}: ${errorText}`
      );
    }

    const data = await response.json();
    const { signature, expire, token } = data;
    return { signature, expire, token };
  } catch (error) {
    throw new Error(`Authentication request failed: ${error.message}`);
  }
};
const onError = (err) => console.log(err);
// const onSuccess = (res) => console.log(res);

const Imagekit = ({ id, multiple, onSuccess }) => {
  return (
    <div className=" hidden">
      <IKContext
        urlEndpoint={url}
        publicKey={publicKey}
        authenticator={authenticator}
      >
        <IKUpload
          onError={onError}
          onSuccess={onSuccess}
          id={id}
          multiple={multiple ? true : false}
        />
      </IKContext>
    </div>
  );
};

export default Imagekit;
