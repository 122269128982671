import React, { forwardRef, useEffect, useImperativeHandle, useRef, useState } from "react";
import SideBar from "../Parts/SideBar2";
import ProgressSteps4 from "./ProgressSteps4_students";
import { useNavigate, useParams } from "react-router-dom";
import FieldCol1 from "../Parts/FieldCol1";
import { useDispatch, useSelector } from "react-redux";
import { updateExperience, addExperience, selectStudent, resetStudents } from "../../redux/studentsSlice";
import { addStudent, editStudent } from "../../api/studentApi";
import { formatDate } from "../professor/experience";
import { toast } from 'react-toastify';

const Experience = forwardRef((props, ref) => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const studentData = useSelector(selectStudent) || null
  const experienceData = useSelector((state) => studentData.experience);
  const [localData, setLocalData] = useState(experienceData);
  const sectionRef = useRef(experienceData);

  useEffect(() => {
    setLocalData(studentData.experience || []);
    sectionRef.current = studentData.experience || [];
  }, [studentData.experience]);

  useEffect(() => {
    sectionRef.current = localData
  }, [localData])

  const handleExperienceChange = (index, key, value) => {
    setLocalData(prevState => {
      const updatedExperience = [...prevState];
      updatedExperience[index] = { ...updatedExperience[index], [key]: value };
      return updatedExperience;
    });
  };

  const handleAddExperience = () => {
    setLocalData(prevState => [
      ...prevState,
      { designation: '', org: '', startDate: null, endDate: null, desc: '' }
    ]);
  };

  const removeExp = (ind) => {
    const newExp = localData.filter((_, i) => i !== ind)
    setLocalData(newExp);
    dispatch(updateExperience({ index: ind, delete: true }))
  }

  const handlePrev = () => {
    sectionRef.current = localData;
    localData.forEach((experience, index) => {
      dispatch(updateExperience({ index, experience, delete: false }));
    });
  }

  const handleSubmit = async () => {
    // const experience = localData.experience;

    // // Validate experience details
    // if (!Array.isArray(experience) || experience.length === 0) {
    //     alert("Experience data is missing.");
    //     return false; // Return false for validation failure
    // }

    // for (const exp of experience) {
    //     if (!exp.designation || !exp.org || !exp.startDate || !exp.endDate || !exp.desc) {
    //         alert("All fields are required for each experience entry.");
    //         return false; // Return false for validation failure
    //     }

    //     // Ensure endDate is after startDate
    //     if (new Date(exp.endDate) <= new Date(exp.startDate)) {
    //         alert("End date must be after the start date for each experience entry.");
    //         return false; // Return false for validation failure
    //     }
    // }

    sectionRef.current = localData;
    const updatedStudent = { ...studentData, experience: localData };
    try {
      if (!id) {
        await addStudent(updatedStudent);
        toast.success('Student added successfully.');
      } else {
        await editStudent(id, updatedStudent);
        toast.success('Student updated successfully.');
      }

      dispatch(resetStudents());
      navigate('/dashboard/student');
    } catch (error) {
      console.error("Error submitting data:", error);
      if (error?.response?.data) {
        console.error("Server response:", error.response.data.error);
        toast.error(`Error: ${error.response.data.message || 'An error occurred while submitting the data.'}`);
      } else {
        toast.error('An error occurred while submitting the data.');
      }
    }

  };

  useImperativeHandle(ref, () => ({
    handleSubmit,
    handlePrev,
  }));

  return (
    <div className="bg-purple-50 justify-start items-start inline-flex">
      <SideBar />

      <div className="grow ml-[15.5vw] shrink basis-0 self-stretch justify-start items-start flex">
        <div className="grow shrink basis-0 pt-4 pb-24 justify-start items-center gap-16 flex">
          <div className="grow shrink basis-0 px-8 justify-center items-start flex">
            <div className="grow shrink basis-0 flex-col justify-start items-start gap-12 inline-flex">

              <div className="self-stretch flex-col justify-start items-start gap-8 flex">
                <div className="self-stretch flex-col justify-start items-start gap-6 flex">
                  <div className="flex justify-center items-center 3xl:ml-[15vw]">
                    <ProgressSteps4 page="4" />
                  </div>

                  <div className="self-stretch justify-start items-start gap-8 inline-flex">
                    <div className="grow shrink basis-0 flex-col justify-start items-start gap-1.5 inline-flex">
                      <div className="self-stretch h-[70px] flex-col justify-center items-start gap-1.5 flex">
                        <p className="text-zinc-700 text-4xl font-bold leading-tight">
                          Experience
                        </p>
                      </div>
                    </div>
                  </div>

                </div>

                {localData.map((experience, index) => (
                  <div className="self-stretch justify-start items-start gap-6 inline-flex" key={index}>
                    <div className="grow shrink basis-0 bg-purple-100 p-4 rounded-lg justify-start items-start gap-8">
                      <div className="grow shrink basis-0 justify-start items-start gap-1.5 flex">
                        <div className="self-stretch h-[100px] flex-col justify-start items-start gap-1.5 flex">
                          <label
                            htmlFor={`designation${index}`}
                            className="text-zinc-700 text-base font-semibold leading-tight"
                          >
                            Experience Designation
                          </label>
                          <div className="self-stretch px-3.5 py-2.5 bg-white rounded-lg shadow border border-gray-900 justify-start items-center gap-2 inline-flex">
                            <div className="grow shrink basis-0 h-6 justify-start items-center gap-2 flex">
                              <input
                                id={`designation${index}`}
                                type="text"
                                placeholder="“School Name”"
                                className="grow w-[540px] shrink basis-0 text-zinc-700 text-base font-normal leading-normal focus:outline-none"
                                value={experience.designation}
                                onChange={(e) => handleExperienceChange(index, 'designation', e.target.value)}
                              />
                            </div>
                          </div>
                        </div>

                        <div className="grow shrink basis-0 flex-col justify-start items-start gap-1.5 inline-flex">
                          <div className="self-stretch h-[100px] flex-col justify-start items-start gap-1.5 flex">
                            <label
                              htmlFor={`org${index}`}
                              className="text-zinc-700 text-base font-semibold leading-tight"
                            >
                              Organization
                            </label>
                            <div className="self-stretch px-3.5 py-2.5 bg-white rounded-lg shadow border border-gray-900 justify-start items-center gap-2 inline-flex">
                              <div className="grow shrink basis-0 h-6 justify-start items-center gap-2 flex">
                                <input
                                  id={`org${index}`}
                                  type="text"
                                  placeholder="“School Name”"
                                  className="grow shrink basis-0 text-zinc-700 text-base font-normal leading-normal focus:outline-none"
                                  value={experience.org}
                                  onChange={(e) => handleExperienceChange(index, 'org', e.target.value)}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="self-stretch w-[100%] justify-start items-start gap-6 inline-flex">
                        <div className="w-52 h-[70px] justify-end items-start gap-8 flex">
                          <div className="w-52 h-[70px] flex-col justify-start items-start gap-1.5 inline-flex">
                            <label htmlFor={experience} className="text-zinc-700 text-base font-semibold leading-tight">
                              Start Date
                            </label>
                            <div className="self-stretch h-11 flex-col justify-start items-end gap-2 flex">
                              <div className="self-stretch h-11 px-4 py-2.5 bg-white rounded-lg shadow border border-gray-900 justify-center items-center gap-2 inline-flex">
                                <input
                                  type="date"
                                  id={experience}
                                  name={experience.startDate}
                                  value={formatDate(experience.startDate)}
                                  onChange={e => handleExperienceChange(index, 'startDate', e.target.value)}
                                  className="focus:outline-none text-zinc-700"
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="w-52 h-[70px] justify-end items-start gap-8 flex mb-3 sm:mb-5 md:mb-7 lg:mb-8">
                          <div className="w-52 h-[70px] flex-col justify-start items-start gap-1.5 inline-flex">
                            <label htmlFor={experience + "End"} className="text-zinc-700 text-base font-semibold leading-tight">
                              End Date
                            </label>
                            <div className={`self-stretch h-11 flex-col justify-start items-end gap-2 flex ${experience.currentlyWorking && 'opacity-50 cursor-not-allowed'}`}>
                              <div className={`self-stretch h-11 px-4 py-2.5 bg-white rounded-lg shadow border border-gray-900 justify-center items-center gap-2 inline-flex`}>
                                <input
                                  type="date"
                                  id={experience + "End"}
                                  name={experience.endDate}
                                  value={formatDate(experience.endDate)}
                                  onChange={e => handleExperienceChange(index, "endDate", e.target.value)}
                                  className={`focus:outline-none text-zinc-700 ${experience.currentlyWorking && 'opacity-50 cursor-not-allowed'}`}
                                  disabled={experience.currentlyWorking}
                                />
                              </div>
                            </div>
                            <div className="flex items-center mt-2">
                              <input
                                type="checkbox"
                                id={`currentlyWorking-${index}`}
                                checked={experience.currentlyWorking}
                                onChange={e => handleExperienceChange(index, 'currentlyWorking', e.target.checked)}
                                className="mr-2"
                              />
                              <label htmlFor={`currentlyWorking-${index}`} className="text-zinc-700 text-sm font-semibold leading-tight">
                                Currently working here
                              </label>
                            </div>
                          </div>
                        </div>
                      </div>


                      <FieldCol1
                        fieldName="Experience Description"
                        fieldPlace="I have experience XYZ..."
                        name="desc"
                        value={experience.desc}
                        handleInput={(e) => handleExperienceChange(index, 'desc', e.target.value)}
                      />
                      <p className="text-violet-700 text-base font-semibold leading-tight cursor-pointer mb-4" onClick={() => removeExp(index)}>Remove</p>
                    </div>
                  </div>
                ))}
                <p className="text-violet-700 text-base font-semibold leading-tight cursor-pointer mb-4" onClick={handleAddExperience}>
                  + Add Experience
                </p>

              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
});

export default Experience;