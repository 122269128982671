import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Professor from "./Components/professor/pages.jsx";
import CollegePage from "./Components/college/Page.jsx";
import UniversityPage from "./Components/university/UniversityPage.jsx";
import AddCourse from "./Components/course/addCourse.jsx";
import AddTopic from "./Components/course/addtopic.jsx";
import AddSemester from "./Components/course/addSemester.jsx";
import AddDegree from "./Components/course/addDegree.jsx";
import Student from "./Components/student/Pages.jsx"
import CollegeDashboard from "./Components/Dashboard/CollegeDashboard.jsx";
import UniversityDashboard from "./Components/Dashboard/UniversityDashboard.jsx";
import StudentDashboard from "./Components/Dashboard/StudentDashboard.jsx";
import ProfessorDashboard from "./Components/Dashboard/ProfessorDashboard.jsx";
import UserProfile from "./Components/UserProfile/UserProfile.jsx";
import UserDashboard from "./Components/Dashboard/UserDashboard.jsx";
import DocRepoDashboard from "./Components/Dashboard/DocRepoDashboard.jsx";
import Pdf from "./Components/documentRepo/Pdf.jsx";
import Video from "./Components/documentRepo/Video.jsx";
import Article from "./Components/documentRepo/Article.jsx";
import TopicDashboard from "./Components/Dashboard/TopicDashboard.jsx";
import CourseDashboard from "./Components/Dashboard/CourseDashboard.jsx";
import SemesterDashboard from "./Components/Dashboard/SemesterDashboard.jsx";
import DegreeDashboard from "./Components/Dashboard/DegreeDashboard.jsx";

import Login from "./Components/auth/Login.jsx";
import Signup from "./Components/auth/Signup.jsx";
import ForgotPassword from "./Components/auth/ForgotPassword.jsx";
import ResetPassword from "./Components/auth/ResetPassword.jsx";
import ConfirmPassword from "./Components/auth/ConfirmPassword.jsx";

import AddUser from "./Components/user/AddUser.jsx";
import Protected from "./Components/auth/Protected.jsx";
import ConfigurationDashboard from "./Components/Dashboard/ConfigurationDashboard.jsx";

const App = () => {
  return (
    // <Router>
    <Routes>
      <Route path="/" element={<Login />} />
      <Route path="/signup" element={<Signup />} />
      <Route path="/forgot-password" element={<ForgotPassword />} />
      <Route path="/reset-password" element={<ResetPassword />} />
      <Route path="/confirm-password" element={<ConfirmPassword />} />

      {/* dashboard routes (according to sidebar) */}
      <Route path="/dashboard/configuration" element={<Protected>  <ConfigurationDashboard /> </Protected>} />
      <Route path="/dashboard/professor" element={<Protected>  <ProfessorDashboard /> </Protected>} />
      <Route path="/dashboard/student" element={<Protected><StudentDashboard /></Protected>} />
      <Route path="/dashboard/college" element={<Protected><CollegeDashboard /></Protected>} />
      <Route path="/dashboard/university" element={<Protected><UniversityDashboard /></Protected>} />

      <Route path="/dashboard/document" element={<Protected><DocRepoDashboard /></Protected>} />

      <Route path="/dashboard/topic" element={<Protected><TopicDashboard /></Protected>} />
      <Route path="/dashboard/course" element={<Protected><CourseDashboard /></Protected>} />
      <Route path="/dashboard/semester" element={<Protected><SemesterDashboard /></Protected>} />
      <Route path="/dashboard/degree" element={<Protected><DegreeDashboard /></Protected>} />

      <Route path="/user_" element={<Protected><UserDashboard /></Protected>} />

      <Route path="/user" element={<Protected><UserProfile /></Protected>} />

      {/* admin routes */}
      <Route path="/admin/user/:id?" element={<Protected><AddUser /></Protected>} />
      <Route path="/admin/college/:id?" element={<Protected><CollegePage /></Protected>} />
      <Route path='/admin/student/:id?' element={<Protected><Student /></Protected>} />
      <Route path="/admin/professor/:id?" element={<Protected><Professor /></Protected>} />
      <Route path="/admin/university/:id?" element={<Protected><UniversityPage /></Protected>} />

      <Route path="/admin/course/:id?" element={<Protected><AddCourse /></Protected>} />
      <Route path="/admin/topic/:id?" element={<Protected><AddTopic /></Protected>} />
      <Route path="/admin/semester/:id?" element={<Protected><AddSemester /></Protected>} />
      <Route path="/admin/degree/:id?" element={<Protected><AddDegree /></Protected>} />

      <Route path="/admin/pdf/:id?" element={<Protected><Pdf /></Protected>} />
      <Route path="/admin/video/:id?" element={<Protected><Video /></Protected>} />
      <Route path="/admin/article/:id?" element={<Protected><Article /></Protected>} />


    </Routes>
    // </Router>
  );
};

export default App;
