import API from './config';

// university last section

export const addUniversity = async(university) => {
  try {
    const res = await API.post('university/add', university);
    return res.data;
  } catch (error) {
    console.error(error);
  }
}

export const editUniversity = async(id, university) => {
  try {
    const res = await API.patch(`university/edit/${id}`, university);
    return res.data;
  } catch (error) {
    console.error(error);
  }
};

// university dashboard

export const getUniversityById = async(id) => {
  try {
    const res = await API.get(`university/${id}`);
    return res;
  } catch (err) {
    console.log(err);
  }
}


export const getUniversities = async() => {
  try {
    const res = await API.get('university/all');
    return res.data;
  } catch (error) {
    console.error(error);
  }
}

export const toggleActive = async(id) => {
  try {
    const res = await API.put(`university/active/${id}`);
    return res.data;
  } catch (error) {
    throw error;
  }
}


export const delUniversity = async(id) => {
  try {
    await API.delete(`university/delete/${id}`);
  } catch (error) {
    console.error(error);
  }
};
