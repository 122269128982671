import React from "react";

const FieldCol1 = ({ fieldName, fieldPlace, name, value, handleInput, required }) => {

  return (
    <div className="self-stretch h-40 flex-col justify-start items-start gap-1.5 w-[77vw]">
      <div className="self-stretch grow shrink basis-0 flex-col justify-start items-start gap-1.5 flex">
        <label
          htmlFor={name}
          className="text-zinc-700 text-base font-semibold leading-tight"
        >
          {fieldName}{required && <span className="text-red-500">*</span>}
        </label>
        <div className="self-stretch grow shrink basis-0 px-3.5 py-3 bg-white rounded-lg shadow border border-gray-900 justify-start items-start gap-2 inline-flex">
          <textarea
            type="text"
            required
            id={name}
            name={name}
            value={value}
            placeholder={fieldPlace}
            onChange={handleInput}
            className="grow shrink basis-0 self-stretch text-zinc-700 text-base font-normal leading-normal focus:outline-none"
          ></textarea>
        </div>
      </div>
    </div>
  );
};

export default FieldCol1;
