import React, { useState, useRef, useEffect } from "react";
import EducationBackground from "./EducationBackground";
import Experience from "./Experience";
import PersonalInfo from "./PersonalInfo";
import Skills from "./Skills";
import { useParams } from "react-router-dom";
import { getStudentById } from "../../api/studentApi";
import { useDispatch, useSelector } from "react-redux";
import { resetStudents, selectStudent, updateStudentData } from "../../redux/studentsSlice";

const Pages = () => {
    const { id } = useParams();
    const dispatch = useDispatch();
    const [page, setPage] = useState(1);
    const formRefs = useRef({});

    useEffect(() => {
        const fetchStudentData = async () => {
            if (id) {
                try {
                    const res = await getStudentById(id);
                    dispatch(updateStudentData(res.data));
                } catch (error) {
                    console.error('Failed to fetch student data:', error);
                }
            }
            else dispatch(resetStudents());
        };

        fetchStudentData();
    }, [id]);

    const nextPage = () => {
        if (formRefs.current[page]?.handleSubmit) {
            const isValid = formRefs.current[page].handleSubmit();
            if (isValid) {
                setPage((prevPage) => Math.min(prevPage + 1, 4));
            }
        } else {
            setPage((prevPage) => Math.min(prevPage + 1, 4));
        }
    };
    

    const previousPage = () => {
        setPage(page => Math.max(1, page - 1));
        if (page === 4) formRefs.current[page]?.handlePrev();
    };

    const renderPage = () => {
        switch (page) {
            case 1:
                return <PersonalInfo ref={(el) => (formRefs.current[1] = el)} />;
            case 2:
                return <EducationBackground ref={(el) => (formRefs.current[2] = el)} />;
            case 3:
                return <Skills ref={(el) => (formRefs.current[3] = el)} />;
            case 4:
                return <Experience ref={(el) => (formRefs.current[4] = el)} />;
            default:
                return null; // Handle invalid page numbers
        }
    };

    return (
        <div className="flex flex-col bg-purple-50 justify-center">
            {renderPage()}
            <div className="flex self-stretch pb-16 -mt-10 px-10 justify-center items-center gap-4 space-x-4 ml-[16.5vw]">
                {page > 1 && (
                    <button onClick={previousPage} className="grow shrink basis-0 h-12 px-5 py-3 bg-amber-100 rounded-lg shadow border border-amber-500 justify-center items-center gap-2 flex">
                        <p className="text-amber-500 text-base font-semibold leading-normal">
                            Back
                        </p>
                    </button>
                )}
                {page < 4 && (
                    <button onClick={nextPage} className="grow shrink basis-0 h-12 px-5 py-3 bg-amber-500 rounded-lg shadow border justify-center items-center gap-2 flex">
                        <p className="text-white text-base font-semibold leading-normal">
                            ({page}/4) Next
                        </p>
                    </button>
                )}
                {page === 4 && (
                    <button onClick={nextPage} className="grow shrink basis-0 h-12 px-5 py-3 bg-amber-500 rounded-lg shadow border justify-center items-center gap-2 flex">
                        <p className="text-white text-base font-semibold leading-normal">
                            Submit
                        </p>
                    </button>
                )}
            </div>
        </div>
    );
};

export default Pages;
