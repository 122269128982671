import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import DashboardCard from "./dashboardCard";
import Sidebar2 from "../Parts/SideBar2";
import { delStudent, getStudentById, getStudents, toggleActive } from "../../api/studentApi";
import { selectLoggedInUser } from "../../redux/authSlice";
import { useSelector } from "react-redux";
import { toast } from 'react-toastify';

const StudentDashboard = () => {
  const [students, setStudents] = useState([]);
  const [searchQuery, setSearchQuery] = useState('');

  const mainUser = useSelector(selectLoggedInUser);

  const navigate = useNavigate();

  useEffect(() => {
    fetchStudents();
  }, []);

  const fetchStudents = async () => {
    try {
      const data = await getStudents();
      setStudents(data);
    } catch (error) {
      console.error('Failed to fetch students:', error);
    }
  };

  const handleEdit = async (id) => {
    try {
      navigate(`/admin/student/${id}`);
    } catch (err) {
      console.log(err);
    }
  };

  const handleDelete = async (id) => {
    try {
      await delStudent(id);
      setStudents(students.filter(student => student._id !== id)); // Adjust the ID field if necessary
      toast.success("Student deleted successfully.");
    } catch (error) {
      console.error('Failed to delete student:', error);
      toast.error("Failed to delete student. Please try again.");
    }
  };

  const handleActive = async (id, stat) => {
    try {
      const updated = await toggleActive(id, { isActive: !stat })
      setStudents(students.map(p =>
        p._id === id ? { ...p, isActive: updated.isActive } : p
      ));
    } catch (error) {
      console.error(error);
    }
  }

  const filteredStudents = students?.filter((student) => {
    const fullName = `${student.info.name.first} ${student.info.name.last}`.toLowerCase();
    return fullName.includes(searchQuery.toLowerCase());
  });


  return (
    <div className="flex overflow-hidden">
      <Sidebar2 />
      <div className="ml-[15.5vw] p-4">
        <header>
          <h1 className="text-2xl font-bold mb-4">Hi, {mainUser.user.name || "Name"}</h1>
        </header>
        <div className="flex flex-col items-center">
          <div className="flex w-[1200px] border-2 rounded-t-lg px-4 pt-2 justify-between items-center">
            <div className="flex flex-col">
              <h2 className="text-xl font-semibold mb-1">Student Dashboard</h2>
              <p className="text-gray-600 mb-4">Keep track of your students.</p>
            </div>

            <button
              className="h-[39px] px-6 py-2 mb-4 bg-orange-500 text-white rounded"
              onClick={() => navigate("/admin/student")}
            >
              + Add New Student
            </button>
          </div>

          <div className="flex w-[1200px] space-x-[700px] border-2  px-2 py-2 -translate-y-[2px] -translate-x-[14.8px]  ml-[30px] ">
            <div className="flex items-center space-x-2 mb-4">
              <input
                type="text"
                placeholder="Search by name"
                className="border-2 translate-y-[8px] w-[380px] h-[40px] px-4 py-3 rounded-lg"
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
              />

              <button className="translate-y-[8px] h-[40px] px-4 py-2 bg-gray-200 rounded">
                Filters
              </button>
            </div>
          </div>
        </div>

        <div className="w-[1200px] ml-[15.5px] grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4 border-2 rounded-b-lg px-4 py-4 -translate-yx-[38px] -translate-y-[3px]">
          {filteredStudents?.map((student, index) => (
            <DashboardCard
              key={student._id ?? index}
              name={student.info.name.first + " " + student.info.name.last}
              // code={student.code}
              date={student.createdAt.substring(0, 10)}
              isActive={student.isActive}
              toggleActive={() => handleActive(student._id)}
              deletee={() => handleDelete(student._id)}
              edit={() => handleEdit((student._id))}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

export default StudentDashboard;
